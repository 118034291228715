/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent, FormEvent, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { es } from 'date-fns/locale';
import { DataContext } from '../context/DataContext';
import { addDays } from 'date-fns';
import { InitTitle } from '../components/InitTitle';
import { FaRegCalendar, FaRegClock } from 'react-icons/fa';
import ReactPixel from 'react-facebook-pixel';
import { language } from "../helpers/lang"
import moment from 'moment-timezone';
import 'moment/locale/es';
import Swal from 'sweetalert2';
import { reserveSlotTemp } from '../helpers/reserveSlot';
import { MdOutlineClear } from 'react-icons/md';

export const SelectDay = () => {

  type T = keyof typeof language;

  const idioma = useParams();
  const lang:string = idioma.lang || 'es'

  const { 
    dataState, 
    getReserve, 
    cleanReserve, 
    getDayHour, 
    cleanBuyer, 
    getGenerarHorarios,
    getTicketActive,
    getTicketMaxFunction,
    setVisitantes,
  } = useContext(DataContext);

  const { slotSelected } = dataState;

  const [startDate, setStartDate] = useState<Date>();
  const [startTime, setStartTime] = useState('');
  const [selectValue, setSelectValue] = useState('0');
  const [ tickets, setTickets ] = useState([] as any);


  //contadores entradas
  const [count1, setCount1] = useState(0);
  const [count2, setCount2] = useState(0);
  const [count3, setCount3] = useState(0);


  const [ horas, setHoras ] = useState<string[]>([]);
  const [ aforo, setAForo ] = useState<{aforoActual: number, maxAforo: number}>({aforoActual: 0, maxAforo: 1000});

  //guardar los holidays provenientes desde la administración de dias especiales
  //const [ newHolidays, setNewHolidays ] = useState<Holiday[]>(holidays);
  interface TodayIsHoliday {
    isOpen: boolean;
    status: string | 'Horario normal' | 'Día especial' | 'Día cerrado'
  };
  const [ todayIsHoliday, setTodayIsHoliday ] = useState<TodayIsHoliday>({isOpen: false, status: 'Horario normal'});


  const timeInput = useRef<HTMLSelectElement>(null);
  const navigate = useNavigate();

  const onChangeDate = async (date: Date) => {
    Swal.fire({
      title: language[lang as T].loading,
      text: language[lang as T].loadingText,
      icon: "info",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    setSelectValue("0");
    cleanReserve();
    setHoras([]);
    setStartDate(date);
    list(date);
    const momentObj = moment.tz(date, "America/Santiago");
    setHorarios(momentObj.format("YYYY-MM-DD"));
  };

  const setHorarios = async (date: string) => {
    const day = moment(date).date().toString();
    const month = (moment(date).month()+1).toString();
    
    const resp = await getGenerarHorarios(day, month);
    const data = await resp; 
    
    setIsCalendarOpen(false);
    
    setAForo(data.aforo);
    setTodayIsHoliday({ isOpen: data.isOpen, status: data.msg });
    setHoras(data.horario);
    Swal.close();
  }  

  const onChangeTime = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e) {
      setSelectValue(e.target.value);
      setStartTime(e.target.value.split(".")[0]);
      const reserveTmp = reserveSlotTemp(
        moment(startDate).format("YYYY-MM-DD"),
        parseInt(e.target.value.split(":")[0])
      );
      getReserve(reserveTmp);

      ReactPixel.track("ViewContent", {
        contents: [e.target.value],
      });
    } else {
      cleanReserve();
    }
  };

  const onClickCount = (index:number, value:number) => {
    if(count1 + count2 + count3 <= 19 ) {
      switch(index){
        case 1:
          setCount1( prev => (prev === 0 && value < 0) ? 0 : Math.min(prev + value, 10) );
          break;
        case 2:
          setCount2( prev => (prev === 0 && value < 0) ? 0 : Math.min(prev + value, 9) );
          break;
        case 3:
          setCount3( prev => (prev === 0 && value < 0) ? 0 : Math.min(prev + value, 10) );
          break;
      }
    }
  }

  const [totalCount, setTotalCount] = useState<number>(0);
  
  const onClickCount_new = (id: number, value: number) => {
    let total = 0;
    const newTickets = tickets.map((ticket: any) => {
      if(ticket.id === id && (ticket.count + value) >= 0 && (ticket.count + value) <= 10)
      {
        ticket.count = ticket.count + value;
      }

      total = total + ticket.count 
      return ticket
    });
    setTotalCount(total);
    setTickets(newTickets);
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const d = moment(startDate).format("YYYY-MM-DD");

    getDayHour( d!, startTime);

    //setVisitor({adult: count1, child: count2, fast: count3});
    setVisitantes(tickets);
    //if(count1 <= 0 && count3 <= 0) {
    if(totalCount <= 0 ) {
      Swal.fire('Error', language[lang as T].selectMsgError, 'error');
    } else {
      navigate(`/${lang}/resumebuy`);
    }
  }

  const list = async (date: any) => {
    const dateRectif = moment(date).tz("America/Santiago").format("YYYY-MM-DD");
    const listTickets = await getTicketActive(dateRectif);
    const ticketVigentes = listTickets.filter((ticket: any) => {
      const fechaVig = moment(ticket.fecha_vig);
      if (ticket.fechafrom) {
        const fechaFrom = moment(ticket.fechafrom);
        // Si la fecha elegida es anterior a fechaFrom, se descarta el ticket
        return moment(date).isSameOrAfter(fechaFrom) && moment(date).isSameOrBefore(fechaVig);
      }
      // Si no hay fechafrom, se considera vigente si date es anterior o igual a fecha_vig
      return moment(date).isSameOrBefore(fechaVig);
    });
    //const ticketVigentes = listTickets.filter((ticket: any) => moment(ticket.fecha_vig).isSameOrAfter(moment(date)));
    // const ticketVigentes = listTickets.filter((ticket: any) => 
    //   moment(date).isBetween(moment(ticket.fecha_from), moment(ticket.fecha_vig), null, '[]')
    // );
    seleccionarTipoTicket(ticketVigentes, date);
  }

  const seleccionarTipoTicket = (listTickets: any, date: Date) => {
    if (listTickets === undefined) {
        return [];
    }
    let ticketsSeleccionados: any[] = [];
    ticketsSeleccionados = listTickets
        .filter((ticket: any) => ticket.name.includes("/web"))
        .map((ticket: any) => ({
            ...ticket,
            name: ticket.name.split("/")[0],
            count: 0
        }));

    const checkTickets = async () => {
      const ticketsSeleccionadosFiltrado: any[] = [];
      if (ticketsSeleccionados.length === 0) {
        return;
      }
      for (const ticket of ticketsSeleccionados) {
       const reviewTicket = await getTicketMaxFunction(moment(date).format("YYYY-MM-DD"), ticket.id);
        if (reviewTicket.data && reviewTicket.data.valid === 1) {
          ticketsSeleccionadosFiltrado.push({...ticket, agotado: true});
        } else {
          ticketsSeleccionadosFiltrado.push({...ticket, agotado: false});
        }
      }
      setTickets(ticketsSeleccionadosFiltrado);
    };

    if (ticketsSeleccionados.length > 0) {
      checkTickets();
    }
  };

  useEffect(() => {
    cleanReserve();
    cleanBuyer();
    onChangeDate(new Date());
    list(new Date());
  }, []);

  useEffect(() => {
    ReactPixel.init("1642684832717772");
    ReactPixel.pageView();
  }, []);

  const handleAddToCart = async (ticket: any) => {
    const product = {
      content_ids: [ticket.id],
      content_name: ticket.name,
      content_type: ticket.num_ticket,
      contents: [{ id: ticket.id, quantity: ticket.count }],
      currency: "CLP",
      value: ticket.valor,
    };
    ReactPixel.track("AddToCart", product);
  };

  const handleClearDatePicker = () => {
    list(null);
    setTodayIsHoliday({isOpen: false, status: 'Horario normal'});
    setHoras([])
    setAForo({aforoActual: 0, maxAforo: 1000})
    setTickets([])
    cleanReserve()
    setStartDate(undefined);
  }
  /* const datePickerRef = useRef<ReactDatePicker<never, undefined>>(null);
  const handleOpenCalendar = () => {
    datePickerRef.current?.setOpen(true);
  }; */


  const [isCalendarOpen, setIsCalendarOpen] = React.useState(false);

  const handleOpenCalendar = () => {
    setIsCalendarOpen(true);
  };
 
  return (
    <>
      <InitTitle />
      <div className="box_main container-fluid mt-3">
        <div className='box-container'>
          <form
            onSubmit={ handleSubmit }
          >
            <h4>{language[lang as T].selectTitle}</h4>
            <div className="row select-date-hour">
              <div className="col-md">
                <div className="input_form input_date">
                  <FaRegCalendar className='me-2'/>
                  <div className='w-100 d-flex flex-wrapd-flex flex-row align-items-center justify-content-between'>
                   <div className='w-100 h-100 cursorPointer' onClick={handleOpenCalendar}>
                    <DatePicker 
                      open={isCalendarOpen} 
                      className='select-inside w-100 me-5 customDataPickerSky'
                      selected={ startDate }
                      onChange={ onChangeDate }
                      name='datereserve'
                      placeholderText={language[lang as T].selectFecha}
                      dateFormat={'EEEE d MMMM yyyy'}
                      locale={es}
                      required={true}
                      minDate={new Date()}
                      maxDate={addDays(new Date(), 120)}
                      isClearable={false}
                      autoComplete="off"
                      /> 
                    </div>
                  <MdOutlineClear className='btnClearDatePicker cursorPointer' onClick={handleClearDatePicker}/>
                  </div>
                </div>
              </div>

              <div className="col-md">
                <div className="input_form input_date">
                  <FaRegClock className='me-2'/>
                  {todayIsHoliday.isOpen && (aforo.aforoActual < aforo.maxAforo) ? 
                    <select
                      className="select-inside"
                      name="timreserve"
                      id="timereserve"
                      onChange={ onChangeTime }
                      ref={ timeInput }
                      value={ selectValue }
                    >
                        <option disabled={true} value="0">
                          {language[lang as T].selectHora}
                        </option>
                        {
                          (horas)&&
                          (horas.map((hour,i) => {
                              return (
                                  <option key={i} value={hour}>
                                      {
                                          (hour.split('.')[0]).split(':')[0] + ':' + (hour.split(':')[1])
                                      }
                                  </option>
                              )
                          }))
                        }
                    </select> : <select
                      className="select-inside"
                      name="timreserve"
                      id="timereserve"
                      disabled={!todayIsHoliday.isOpen}
                    >
                      <option disabled={true} value="0">
                          {language[lang as T].selectHora}
                        </option>
                    </select>
                  }
                </div>
              </div>
            </div>
            { todayIsHoliday.status === 'Día normal' && <p>{language[lang as T].selectStatusDayNormal}</p>}
            { todayIsHoliday.status === 'Día especial' && <p>{language[lang as T].selectStatusDaySpecial}</p>}
            { todayIsHoliday.status === 'Día cerrado' && <p style={{color: '#f5222d'}}>{language[lang as T].selectStatusDayClose}</p>}
            
            
            { (aforo.aforoActual >= aforo.maxAforo) && <p style={{color: '#f5222d'}}> {language[lang as T].maxAforo}</p>}
            
            {
              ( Object.keys(slotSelected).length > 0  && (aforo.aforoActual < aforo.maxAforo))
              &&
                <>
                    <div className="row mt-4">
                      <h4>{language[lang as T].selectTipo}</h4>
                    </div>

                    {tickets ? tickets.map((ticket: any) => {
                      return <div key={ticket.id} className='d-block' >
                      <div className="row wborder mb-2 select-tipo-ent">
                          <div className="col-md">
                              <span>{ticket.name}</span>
                          </div>
                          <div className="col-md sd_respon_2">
                              {!ticket.agotado ? ticket.description : <span className='color: red'>{language[lang as T].selectAgotado}</span> }
                          </div>
                          <div className="col-md sd_respon">
                          </div>
                          <div className="col-md text-end">
                              { (slotSelected)&& '$'+(ticket.valor).toLocaleString("de-DE",{style: "currency", currency: "CLP"}) }
                          </div>
                          <div className="col-md text-end" >
                              <button
                                  className='btn_counter'
                                  disabled={ticket.agotado}
                                  onClick={ e => {
                                      e.preventDefault();
                                      onClickCount_new(ticket.id, -1);
                                  }}
                              >
                                  -
                              </button>

                              <input
                                  className='counter text-center'
                                  value={ ticket.count }
                                  readOnly
                              />

                              <button
                                  className='btn_counter'
                                  disabled={ticket.agotado}
                                  onClick={ e => {
                                    e.preventDefault();
                                    onClickCount_new(ticket.id, 1);
                                    handleAddToCart(ticket)
                                }}
                              >
                                  +
                              </button>
                          </div>

                      </div>
                    </div>
                    })

                    :
                    
                    <>

                  
                    <div className='d-block' >
                      <div className="row wborder mb-2 select-tipo-ent">
                          <div className="col-md">
                              <span>{language[lang as T].homeAdulto}</span>
                          </div>
                          <div className="col-md sd_respon_2">
                              {language[lang as T].selectMayores}
                          </div>
                          <div className="col-md sd_respon">
                              {/* { row.description2 || '' } */}
                          </div>
                          <div className="col-md text-end">
                              { (slotSelected)&& '$'+(slotSelected.ticket_types[0].price).toLocaleString("de-DE",{style: "currency", currency: "CLP"}) }
                          </div>
                          <div className="col-md text-end" >
                              <button
                                  className='btn_counter'
                                  onClick={ e => {
                                      e.preventDefault();
                                      onClickCount(1, -1);
                                  }}
                              >
                                  -
                              </button>

                              <input
                                  className='counter text-center'
                                  value={ count1 }
                                  readOnly
                              />

                              <button
                                  className='btn_counter'
                                  onClick={ e => {
                                    e.preventDefault();
                                    onClickCount(1, 1);
                                }}
                              >
                                  +
                              </button>
                          </div>

                      </div>
                    </div>


                    <div className='d-block' >
                      <div className="row wborder mb-2 select-tipo-ent">
                          <div className="col-md">
                              {language[lang as T].homeNino}
                          </div>
                          <div className="col-md sd_respon_2">
                              {language[lang as T].selectEntre}
                          </div>
                          <div className="col-md sd_respon">
                              {/* {language[lang as T].homeInfante} */}
                          </div>
                          <div className="col-md text-end">
                              { (slotSelected)&& '$' + (slotSelected.ticket_types[1].price).toLocaleString("de-DE",{style: "currency", currency: "CLP"}) }
                          </div>
                          <div className="col-md text-end" >
                              <button
                                  className='btn_counter'
                                  onClick={ e => {
                                    e.preventDefault();
                                    onClickCount(2,-1);
                                } }
                              >
                                  -
                              </button>

                              <input
                                  className='counter text-center'
                                  value={ count2 }
                                  readOnly
                              />

                              <button
                                  className='btn_counter'
                                  onClick={ e => {
                                    e.preventDefault();
                                    onClickCount(2,1);
                                } }
                              >
                                  +
                              </button>
                          </div>

                      </div>
                    </div>
                    </>
                    }
                    {/* SUNSET*/}
                    {
                      startDate && (moment(startDate).format("YYYY-MM-DD") === "2024-03-10") &&
                      <div className='d-block' >
                        <div className="row wborder mb-2 select-tipo-ent">
                            <div className="col-md">
                                {language[lang as T].homeSunset}
                            </div>
                            <div className="col-md sd_respon_2">
                                {language[lang as T].selectSunset}
                            </div>
                            <div className="col-md sd_respon">
                                {/* {language[lang as T].homeInfante} */}
                            </div>
                            <div className="col-md text-end">
                                { (slotSelected)&& '$' + (slotSelected.ticket_types[2].price).toLocaleString("de-DE",{style: "currency", currency: "CLP"}) }
                            </div>
                            <div className="col-md text-end" >
                                <button
                                    className='btn_counter'
                                    onClick={ e => {
                                      e.preventDefault();
                                      onClickCount(3,-1);
                                  } }
                                >
                                    -
                                </button>

                                <input
                                    className='counter text-center'
                                    value={ count3 }
                                    readOnly
                                />

                                <button
                                    className='btn_counter'
                                    onClick={ e => {
                                      e.preventDefault();
                                      onClickCount(3,1);
                                  } }
                                >
                                    +
                                </button>
                            </div>

                        </div>
                      </div>
                    }

                    <div className="mb-3">
                      <p className='tips'>
                        *{ language[lang as T].selectTips }
                      </p>
                      {/* <p className='tips'>
                        *{ language[lang as T].selectBirthday }
                      </p> */}
                    </div>   

                    <div className='d-block' >
                      <div className="row">
                          <div className="col-md text-end">
                              <button
                                  disabled={totalCount === 0}
                                  className={`btn-form ${ totalCount === 0 ? 'btn-form-disabled' : 'btn-form-active'}`}
                                  type='submit'
                              >
                                  {language[lang as T].selectContinuar}
                              </button>
                          </div>
                      </div>
                    </div> 
                </>
            }
          </form>
        </div>
      </div>
    </>
  )
}
