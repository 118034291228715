/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState, useCallback, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment-timezone";
import "moment/locale/es";
import { DataContext } from "../context/DataContext";
import { FaRegClock, FaRegCalendar } from "react-icons/fa";
import ReactPixel from "react-facebook-pixel";
import { language } from "../helpers/lang";
import { reserveSlotTemp } from "../helpers/reserveSlot";

import Loadimage from "../assets/img/load_sky.gif";

type StatusType = "loading" | "noboleta" | "failed" | "rejected" | "approved";

export const CheckoutPage = () => {
  type T = keyof typeof language;
  const { lang = "es" } = useParams();
  const navigate = useNavigate();

  const {
    dataState,
    setPayData,
    getReserve,
    setBuyer,
    setPayment,
    getDayHour,
    getDocumentpdf,
    setVisitantes,
    getTicketActive,
  } = useContext(DataContext);

  const { trxResponse, buyer, invoice, slotSelected, hour } = dataState;

  // Estados locales
  const [describe, setDescribe] = useState("");
  const [cantidad, setCantidad] = useState(0);
  const [folio, setFolio] = useState("");
  const [tickets, setTickets] = useState<any[]>([]);
  const [status, setStatus] = useState<StatusType>("loading");
  const [pay, setPay] = useState(false);
  const [correctDay, setCorrectDay] = useState("");
  // Bandera para saber si ya se procesó la transacción (éxito o error)
  const [processed, setProcessed] = useState(false);
  // Estado para controlar el tiempo mínimo de loading en el primer render
  const [initialLoading, setInitialLoading] = useState(true);
  // Bandera para saber si ya se procesó el hash
  const [hashProcessed, setHashProcessed] = useState(false);
  // Para calcular el tiempo transcurrido desde que se monta el componente
  const startTime = useRef(Date.now());
  const MIN_LOADING_TIME = 1000; // 1 segundo

  const hash = window.location.hash.replace("#", "");
  const tab = <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>;

  // Función para parsear parámetros del hash
  const parseHashParams = (str: string) => {
    return str.split("&").reduce((acc, piece) => {
      const [key, value = ""] = piece.split("=");
      return { ...acc, [decodeURIComponent(key)]: decodeURIComponent(value) };
    }, {} as { [key: string]: string });
  };

  const handleHashParams = useCallback(() => {
    // Si ya se procesaron, salimos de la función
    if (hashProcessed) return;
    
    const params = parseHashParams(hash);
    if (params.state && params.state !== "failed") {
      setStatus(params.state as StatusType);
      setPayData(params.id);
    } else {
      setStatus("failed");
      setProcessed(true); // Consideramos que se procesó, aunque haya error
      setTimeout(() => {
        navigate(`/${lang}/`);
      }, 5000);
    }
    setHashProcessed(true);
  }, [hash, lang, navigate, setPayData, hashProcessed]);

  const validarTimePage = useCallback(() => {
    const timePage = localStorage.getItem("timePage");
    if (!timePage) {
      navigate(`/${lang}/`);
      return;
    }
    const fechaActual = new Date();
    const fechaDefinida = new Date(timePage);
    const diferenciaEnMinutos = Math.floor(
      (fechaActual.getTime() - fechaDefinida.getTime()) / (1000 * 60)
    );
    if (diferenciaEnMinutos >= 30) {
      localStorage.clear();
      navigate(`/${lang}/`);
    }
  }, [lang, navigate]);

  const getTicketActivos = useCallback(async () => {
    try {
      const listTickets = await getTicketActive(new Date().toISOString());
      if (listTickets) {
        const ticketsSeleccionados = listTickets
          .filter((ticket: any) => ticket.name.includes("/web"))
          .map((ticket: any) => ({
            ...ticket,
            name: ticket.name.split("/")[0],
            count: 0,
          }));
        setTickets(ticketsSeleccionados);
      }
    } catch (error) {
      console.error("Error fetching tickets:", error);
    }
  }, [getTicketActive]);

  const handleRejectedTransaction = useCallback(() => {
    if (!trxResponse || !trxResponse.dataTrx) return;
    const dayIso = `${trxResponse.dataTrx.date}T${trxResponse.dataTrx.hour}:00.000Z`;
    const timeZone = process.env.REACT_APP_TIMEZONE ?? "America/Santiago";
    const backDate = moment.tz(dayIso, timeZone);
    const backDateDay = backDate.format("YYYY-MM-DD");
    const backDateTime = backDate.format("HH:mm");

    getDayHour(backDateDay, backDateTime);
    const reserve = reserveSlotTemp(
      backDateDay,
      parseInt(backDateTime.split(":")[0])
    );
    getReserve(reserve);

    const arrayDetails = trxResponse.dataTrx.trx_details || [];
    const visitantes = arrayDetails
      .map((ticket: any) => {
        const idTicket = parseInt(ticket.id_ticket);
        const ticketEncontrado = tickets.find((t: any) => t.id === idTicket);
        return ticketEncontrado
          ? {
              ...ticketEncontrado,
              valor: ticket.price,
              count: ticket.quantity,
            }
          : null;
      })
      .filter(Boolean);
    setVisitantes(visitantes);

    setBuyer({
      name: trxResponse.dataTrx.name,
      lastname: trxResponse.dataTrx.last_name,
      email: trxResponse.dataTrx.email,
      phone: trxResponse.dataTrx.phone,
      residencia: trxResponse.dataTrx.resident,
      nation: trxResponse.dataTrx.nation,
      rut: trxResponse.dataTrx.rut,
      dni: trxResponse.dataTrx.dni || trxResponse.dataTrx.rut,
      birthday: trxResponse.dataTrx.birthday,
      promo: trxResponse.dataTrx.informate,
      region: trxResponse.dataTrx.region,
    });

    setTimeout(() => {
      navigate(`/${lang}/pay`);
    }, 5000);
  }, [trxResponse, tickets, getDayHour, getReserve, setVisitantes, setBuyer, lang, navigate]);

  const handleSuccessfulTransaction = useCallback(() => {
    if (!trxResponse) return;
    if (trxResponse.state === "REJECTED") {
      handleRejectedTransaction();
      ReactPixel.track("Purchase", { content_ids: ["REJECTED"] });
      return;
    }

    const { trx_details } = trxResponse;
    const newDescribe = trx_details
      .filter((det: any) => det.quantity > 0)
      .map((det: any) => `${det.quantity} ${det.description}`)
      .join(" + ");
    const totalCantidad = trx_details.reduce(
      (acc: number, det: any) => acc + (det.quantity > 0 ? det.quantity : 0),
      0
    );

    setDescribe(newDescribe);
    setCantidad(totalCantidad);

    ReactPixel.track("Purchase", {
      content_ids: trx_details.map((ticket: any) => ticket.id_ticket),
      contents: trx_details.map((ticket: any) => ({
        id: ticket.id_ticket,
        quantity: ticket.quantity,
      })),
      currency: "CLP",
      value: trxResponse.total,
    });

    const dayIso = `${trxResponse.date}T${trxResponse.hour}:00.000Z`;
    const timeZone = process.env.REACT_APP_TIMEZONE || "America/Santiago";
    const backDate = moment.tz(dayIso, timeZone);
    const backDateDay = backDate.format("YYYY-MM-DD");
    const backDateTime = backDate.format("HH:mm");

    getDayHour(backDateDay, backDateTime);
    const reserve = reserveSlotTemp(
      backDateDay,
      parseInt(backDateTime.split(":")[0])
    );
    getReserve(reserve);
    setCorrectDay(backDate.format("YYYY-MM-DD HH:mm"));

    setBuyer({
      name: trxResponse.name,
      lastname: trxResponse.last_name,
      email: trxResponse.email,
      phone: trxResponse.phone,
      residencia: trxResponse.resident,
      nation: trxResponse.nation,
      rut: trxResponse.rut,
      dni: trxResponse.dni || trxResponse.rut,
      birthday: trxResponse.birthday,
      promo: trxResponse.informate,
      region: trxResponse.region,
    });

    if (!trxResponse.fiscaldocnumber) {
      setStatus("noboleta");
    } else {
      setFolio(trxResponse.fiscaldocnumber);
      setStatus("approved");
    }

    if (trxResponse.state !== "REJECTED" && !pay) {
      const dataPay = {
        trx: trxResponse.id,
        posno: "9999",
        terminaltype: 3,
        terminal: "WEB",
        paytype: 3,
        payflow: "ONLINE",
        paybrand: trxResponse.tbkcardname,
        amount: trxResponse.total,
        fecha: trxResponse.datetrx,
        authcode: trxResponse.tbkauthcode,
        authdate: trxResponse.datetrx,
        lastfour: trxResponse.tbkcardnumber,
      };
      setPayment(dataPay);
      setPay(true);
    }
  }, [
    trxResponse,
    pay,
    getDayHour,
    getReserve,
    setBuyer,
    setPayment,
    handleRejectedTransaction,
  ]);

  // Efecto que procesa la transacción
  useEffect(() => {
    if (!trxResponse) return;

    if (trxResponse.state === "processing") {
      setStatus("loading");
      return;
    }

    if (Object.keys(trxResponse).length === 0) {
      setStatus("failed");
      setProcessed(true);
      setInitialLoading(false);
      return;
    }

    if (!processed) {
      handleSuccessfulTransaction();
      setProcessed(true);
      // Calcular el tiempo transcurrido y aseguramos un mínimo de loading
      const elapsed = Date.now() - startTime.current;
      if (elapsed < MIN_LOADING_TIME) {
        setTimeout(() => setInitialLoading(false), MIN_LOADING_TIME - elapsed);
      } else {
        setInitialLoading(false);
      }
    }
  }, [trxResponse, processed, handleSuccessfulTransaction]);

  useEffect(() => {
    if (trxResponse?.fiscaldocnumber) {
      setFolio(trxResponse.fiscaldocnumber);
    }
  }, [trxResponse]);

  useEffect(() => {
    getTicketActivos();
    validarTimePage();
    handleHashParams();
  }, [getTicketActivos, validarTimePage, handleHashParams]);

  const downloadPdf = async (url: string) => {
    try {
      const pdfResponse = await getDocumentpdf({ url });
      const pdfBlob = await pdfResponse.blob();
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(pdfBlob);
      link.download = "boleta.pdf";
      link.click();
      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  const handleBack = () => {
    navigate(`/${lang}/`);
  };

  const printDocument = () => {
    if (invoice && invoice.create) {
      downloadPdf(invoice.urlPdf);
    }
  };

  // Durante el tiempo mínimo de carga, se muestra la pantalla de loading
  if (initialLoading && status === "loading") {
    return (
      <div className="title container-fluid">
        <h3>ESTAMOS PROCESANDO TU OPERACIÓN</h3>
        <p className="mt-4">Espere un momento.</p>
        <div className="d-flex justify-content-center align-items-center p-5">
          <img src={Loadimage} alt="Cargando" />
        </div>
      </div>
    );
  }

  if (status === "noboleta") {
    return (
      <div className="title container-fluid">
        <h3>¡Pago Recibido!</h3>
        <p className="mt-4">
          Tu pago se ha realizado correctamente, pero tuvimos un inconveniente para
          generar tu boleta. Enviaremos la boleta al correo <b>{buyer.email}</b> tan pronto
          esté disponible.
        </p>
        <button onClick={handleBack} className="btn-form mt-4">
          {language[lang as T].checkoutInicio}
        </button>
      </div>
    );
  }

  if (status === "rejected") {
    return (
      <div className="title container-fluid">
        <h4>HEMOS TENIDO PROBLEMAS CON TU PAGO, INTÉNTALO NUEVAMENTE</h4>
        <p className="mt-4">
          El sitio te redirigirá a la sección de pago para que lo reintentes.
        </p>
      </div>
    );
  }

  if (status === "failed") {
    return (
      <div className="title container-fluid">
        <h3>Ocurrió un problema</h3>
        <p className="mt-4">
          No se pudo procesar la transacción. Intenta nuevamente más tarde.
        </p>
        <button onClick={handleBack} className="btn-form mt-4">
          Volver al Inicio
        </button>
      </div>
    );
  }

  return (
    <>
      <div className="title container-fluid">
        <h3>{language[lang as T].checkoutTitle}</h3>
        <p className="mt-4">{language[lang as T].checkoutDesc}</p>
      </div>
      <div className="box_main container-fluid mt-3">
        <div className="box-container">
          <div className="box_form mb-5">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                console.log("exito");
              }}
            >
              <div className="row">
                <div className="col-md">
                  <h4>{language[lang as T].resumeCompra}</h4>
                </div>
                <div className="col-md text-end">
                  <h4>
                    {language[lang as T].checkoutTrx} {tab}{" "}
                    {trxResponse?.id?.split("-")[4] || ""}
                  </h4>
                </div>
              </div>
              <div className="row">
                <div className="col-md">
                  <div className="input_form input_date">
                    <FaRegCalendar className="me-2" />
                    <input
                      className="input-inside"
                      value={moment(slotSelected.start).format("dddd D MMMM YYYY")}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-md">
                  <div className="input_form input_date">
                    <FaRegClock className="me-2" />
                    <input className="input-inside" value={hour} readOnly />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="d-block p-2">
                  <div className="row wborder back-primary mb-3 ms-1">
                    <div className="col-md-2">
                      <h5 className="mb-0">Total</h5>
                    </div>
                    <div className="col-md-3">
                      <strong>
                        {correctDay.split(" ")[0] + " - " + correctDay.split(" ")[1] + "hrs"}
                      </strong>
                    </div>
                    <div className="col-md-3">
                      <strong>{describe}</strong>
                    </div>
                    <div className="col-md text-end">
                      <strong>
                        {"$ " +
                          (trxResponse?.total ? trxResponse.total.toLocaleString() : "0") +
                          " CLP"}
                      </strong>
                    </div>
                    <div className="col-md text-end">
                      <button className="btn_counter btn-inv" disabled>
                        -
                      </button>
                      <input
                        className="counter text-center back-primary"
                        value={cantidad}
                        readOnly
                      />
                      <button className="btn_counter btn-inv" disabled>
                        +
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <h5>* {language[lang as T].payReturn}</h5>
              </div>
              <div className="row mb-1">
                <h4 className="mt-4">{language[lang as T].checkoutMedio}</h4>
              </div>
              <div className="row">
                <div>
                  <div className="input_form w-100 d-flex justify-content-between align-items-center">
                    <span>Web Pay</span>
                    <img
                      className="img_trj"
                      src={require("../assets/img/BotonWebPay-img.jpg")}
                      alt="Web Pay"
                    />
                  </div>
                </div>
              </div>
              <div className="row mb-1">
                <h4 className="mt-4">{language[lang as T].checkoutBoleta}</h4>
              </div>
              <div className="row">
                <div>
                  <div className="input_form w-100 d-flex justify-content-between align-items-center">
                    {folio ? (
                      <>
                        <span>
                          {language[lang as T].checkoutNumBol} {folio}
                        </span>
                        <span className="text-end link-span" onClick={printDocument}>
                          {language[lang as T].checkoutDescarga}
                        </span>
                      </>
                    ) : (
                      <span>
                        {language[lang as T].checkoutEmail} <b>{buyer.email}</b>
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md text-start">
                  <h4>{language[lang as T].checkoutRemember}</h4>
                </div>
              </div>
              <div className="row">
                <div className="col-md text-end">
                  <button onClick={handleBack} className="btn-form mt-2" type="button">
                    {language[lang as T].checkoutInicio}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}